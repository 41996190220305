import { useState, useRef } from 'react';
import {
  Box,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  Card,
  CardContent,
  ClickAwayListener,
  Grow,
  MenuList,
  MenuItem,
  Paper,
  Popper,
  Link,
  Stack,
  useTheme,
} from '@mui/material';
import { Skeleton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faAngleUp,
  faArrowRotateRight,
  faEllipsisV,
  faLock,
  faLockOpen,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { components } from 'openapi-types';
import { useDeleteUser, useGetUsers, useReinviteUser, useUserChangeTwoAuth } from 'api/users';
import { ConfirmationDialog } from 'component/base/ConfirmDialog';

export default function RowSkeleton() {
  const rows = () => {
    return Array.from({ length: 3 }).map((v, i) => (
      <TableRow key={i}>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
      </TableRow>
    ));
  };

  return <>{rows()}</>;
}

type User = NonNullable<components['schemas']['UserList']['result']>[0];

export type UserPerPage = 10 | 50 | 100;
export interface UserPaginationState {
  perPage: UserPerPage;
  activePageNumber: number;
  filter: string;
  sortAttr: 'name' | 'status_2fa';
  sortOrder: 'asc' | 'desc';
}

type UserRowProps = { readonly user: User };

function UserRow({ user }: UserRowProps) {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [openTableMenu, setOpenTableMenu] = useState<boolean>(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const { mutateAsync: deleteUser } = useDeleteUser({ userId: user.id ?? '' });
  const { mutateAsync: changeTwoFactorAuth } = useUserChangeTwoAuth({ userId: user.id ?? '' });
  const { mutateAsync: reinviteUser } = useReinviteUser({ userId: user.id ?? '' });

  return (
    <TableRow key={user.id}>
      <TableCell>
        {user.firstname} {user.lastname}
      </TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell>{user.twofactor_enabled ? t('enabled') : t('disabled')}</TableCell>
      <TableCell>{user.twofactor_required ? t('yes') : t('no')}</TableCell>
      <TableCell>{t(user.status?.toString() ?? '')}</TableCell>
      <TableCell
        sx={{
          textAlign: 'center',
        }}
      >
        <Button
          ref={anchorRef}
          variant="text"
          color="primary"
          sx={{
            minHeight: '2.75rem',
          }}
          fullWidth
          onClick={async () => {
            setOpenTableMenu(true);
          }}
        >
          <FontAwesomeIcon size="lg" icon={faEllipsisV} onClick={() => {}} />
          <ConfirmationDialog
            action="delete"
            onConfirm={async () => {
              deleteUser(undefined, {
                onSuccess: () => {
                  setOpenConfirmationDialog(false);
                },
              });
            }}
            description={t('delete_description', {
              name: `${user.firstname} ${user.lastname}`,
            })}
            onClose={() => setOpenConfirmationDialog(false)}
            open={openConfirmationDialog}
            title={t('delete_user', {
              name: `${user.firstname} ${user.lastname}`,
            })}
          />
          <Popper
            popperOptions={{
              placement: 'bottom-start',
            }}
            open={openTableMenu}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={() => setOpenTableMenu(false)}>
                    <MenuList
                      autoFocusItem={openTableMenu}
                      id="menu-list-grow"
                      onKeyDown={() => {}}
                    >
                      {user.status === 'invited' ? (
                        <MenuItem
                          onClick={e => {
                            e.stopPropagation();
                            e.preventDefault();
                            reinviteUser();
                            setOpenTableMenu(false);
                          }}
                        >
                          <Stack spacing={1} direction="row" alignItems="center">
                            <FontAwesomeIcon
                              color={theme.palette.primary.main}
                              icon={faArrowRotateRight}
                            />
                            <Box>{t('resend_invitation')}</Box>
                          </Stack>
                        </MenuItem>
                      ) : null}
                      {!user.twofactor_required && (
                        <MenuItem
                          onClick={e => {
                            e.stopPropagation();
                            e.preventDefault();
                            changeTwoFactorAuth({ twofa: true });
                            setOpenTableMenu(false);
                          }}
                        >
                          <Stack spacing={1} direction="row" alignItems="center">
                            <FontAwesomeIcon color={theme.palette.primary.main} icon={faLock} />{' '}
                            <Box>{t('force_2factor_auth')}</Box>
                          </Stack>
                        </MenuItem>
                      )}
                      {user.twofactor_required === true && (
                        <MenuItem
                          onClick={e => {
                            e.stopPropagation();
                            e.preventDefault();
                            changeTwoFactorAuth({ twofa: false });
                            setOpenTableMenu(false);
                          }}
                        >
                          <Stack spacing={1} direction="row" alignItems="center">
                            <FontAwesomeIcon color={theme.palette.primary.main} icon={faLockOpen} />{' '}
                            <Box>{t('force_remove_2factor_auth')}</Box>
                          </Stack>
                        </MenuItem>
                      )}
                      <MenuItem
                        onClick={e => {
                          e.stopPropagation();
                          e.preventDefault();
                          setOpenTableMenu(false);
                          setOpenConfirmationDialog(true);
                        }}
                      >
                        <Stack spacing={1} direction="row" alignItems="center">
                          <FontAwesomeIcon color={theme.palette.primary.main} icon={faTrash} />{' '}
                          <Box>{t('security_delete_user')}</Box>
                        </Stack>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Button>
      </TableCell>
    </TableRow>
  );
}

export function UsersList({ isWpSiteControl }: { readonly isWpSiteControl: boolean }) {
  const { t } = useTranslation();
  const { data, status } = useGetUsers();
  const users = data?.data.result ?? [];

  const [pagination, setPagination] = useState<UserPaginationState>({
    perPage: 10 as UserPerPage,
    activePageNumber: 1,
    filter: '',
    sortAttr: 'name',
    sortOrder: 'asc',
  });
  const opositeSortOrder = pagination.sortOrder === 'asc' ? 'desc' : 'asc';

  const renderTableRows = () => {
    if (status === 'pending') {
      return <RowSkeleton />;
    }

    return users.map(user => {
      if (!user) {
        return null;
      }
      return <UserRow key={user?.id ?? ''} user={user} />;
    });
  };

  return (
    <Card sx={{ marginTop: '0px !important' }}>
      <CardContent>
        <TableContainer>
          <Table aria-label="Users List Table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Box component="span">
                    <Link
                      onClick={() => {
                        setPagination({
                          ...pagination,
                          sortAttr: 'name',
                          sortOrder: pagination.sortAttr === 'name' ? opositeSortOrder : 'asc',
                        });
                      }}
                    >
                      {t('name')}{' '}
                      {pagination.sortAttr === 'name' ? (
                        <Box display="inline-block" marginLeft={1}>
                          <FontAwesomeIcon
                            icon={pagination.sortOrder === 'asc' ? faAngleUp : faAngleDown}
                          />
                        </Box>
                      ) : null}
                    </Link>
                  </Box>
                </TableCell>
                <TableCell>{t('email')}</TableCell>
                <TableCell>
                  <Link
                    onClick={() => {
                      setPagination({
                        ...pagination,
                        sortAttr: 'status_2fa',
                        sortOrder: pagination.sortAttr === 'status_2fa' ? opositeSortOrder : 'asc',
                      });
                    }}
                  >
                    {t('2fa_authentication')}
                    {pagination.sortAttr === 'status_2fa' ? (
                      <Box display="inline-block" marginLeft={1}>
                        <FontAwesomeIcon
                          icon={pagination.sortOrder === 'asc' ? faAngleUp : faAngleDown}
                        />
                      </Box>
                    ) : null}
                  </Link>
                </TableCell>
                <TableCell>{t('2fa_required')}</TableCell>
                <TableCell>{t('status')}</TableCell>
                <TableCell width={70}>{t('action')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderTableRows()}</TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}
